import { render, staticRenderFns } from "./CmpLotList.vue?vue&type=template&id=4c2fb24a&"
import script from "./CmpLotList.vue?vue&type=script&lang=js&"
export * from "./CmpLotList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports